import React, { useState } from 'react'
import {
  Heading,
  Spinner,
  VStack,
  IconButton,
  SlideFade,
  Button,
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { withRouter } from 'react-router-dom'
import Typed from 'react-typed'
import Sound from '@sidpagariya/react-sound'

const Movie = (props) => {
  let [doneLoading, setDoneLoading] = useState(false)
  let [doneTyping, setDoneTyping] = useState(false)
  let [nextRevealed, setNextRevealed] = useState(false)
  let [volume, setVolume] = useState(50)
  let delayT = 100

  const onLoad = () => {
    setDoneLoading(true)
  }

  if (volume < 50 && volume > 0) {
    setTimeout(() => setVolume(volume - 5), delayT)
  }

  return (
    <VStack spacing={8}>
      {doneLoading ? (
        <Heading fontSize="3xl" whiteSpace="pre" lineHeight="tall">
          <Typed
            strings={[
              '^500We met a summer night^700\n' +
                'And I held you tight^700\n' +
                'In the golden light^1500',
              'And ever since that time^700\n' +
                "I've been burnin' bright^700\n" +
                'A fire deep inside^400',
              'I just wanna feel your love^700\n' +
                'I need your love^800\n' +
                'Wake me back to life^1800',
              'Give me give me one more night^700\n' +
                "And I'll hold you tight^700\n" +
                'In the golden light^400',
              'In the golden light^2000\n' +
                'In the golden light^2500\n' +
                'In the golden light^2500\n' +
                'In the golden light^4000',
            ]}
            typeSpeed={40}
            fadeOut
            onComplete={() => {
              setTimeout(() => setVolume(volume - 5), 6000 + delayT)
              setDoneTyping(true)
            }}
          />

          <br />
          <br />

          <SlideFade in={doneTyping}>
            <Button
              onClick={() => {
                window.open('https://cytu.be/r/mahek_and_sid_date')
                setNextRevealed(true)
              }}
            >
              Let's watch a movie together 🥰
            </Button>
          </SlideFade>
          <SlideFade in={nextRevealed}>
            <IconButton
              variant="outline"
              colorScheme="blue"
              aria-label="Next Screen"
              icon={<ChevronRightIcon />}
              onClick={() => props.history.push('/finally')}
            />
          </SlideFade>
        </Heading>
      ) : (
        <Spinner size="xl" />
      )}
      <Sound
        url="/golden-light.mp3"
        playStatus={Sound.status.PLAYING}
        volume={volume}
        onLoad={onLoad}
        playFromPosition={15000}
      />
    </VStack>
  )
}

export default withRouter(Movie)
