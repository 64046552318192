import React, { useState } from 'react'
import {
  Heading,
  Spinner,
  VStack,
  IconButton,
  SlideFade,
  Button,
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { withRouter } from 'react-router-dom'
import Typed from 'react-typed'
import Sound from 'react-sound'

const Personality = (props) => {
  let [doneLoading, setDoneLoading] = useState(false)
  let [doneTyping, setDoneTyping] = useState(false)
  let [nextRevealed, setNextRevealed] = useState(false)
  let [firstDoneTyping, setFirstDoneTyping] = useState(false)
  let [volume, setVolume] = useState(50)
  let firstTyping = undefined
  let secondTyping = undefined
  let delayT = 100

  const onLoad = () => {
    setDoneLoading(true)
  }

  if (volume < 50 && volume > 0) {
    setTimeout(() => setVolume(volume - 5), delayT)
  }

  return (
    <VStack spacing={8}>
      {doneLoading ? (
        <Heading fontSize="3xl" whiteSpace="pre" lineHeight="tall">
          <Typed
            strings={[
              'Do you remember when we first texted each other^100' +
                '.^100' +
                '.^100' +
                '.^100' +
                '?^2000\n' +
                [
                  'It was a warm Saturday night 🌃^2000',
                  '9:12pm, March 20th, 2021^1000',
                  "My phone screen glowed with a <span style='font-style: italic;'>`Mahek invited you to start the chat`</span>^1000",
                  '...but my dumba** only saw the notification at 1:19am 🥲^1000',
                  'ANYWAYSSSSSS^500',
                  "Since then, we've together:",
                ].join('\n'),
            ]}
            typeSpeed={40}
            backSpeed={50}
            backDelay={2000}
            typedRef={(type) => (firstTyping = type)}
            onComplete={() => {
              setTimeout(() => firstTyping.destroy(), 3000)
              setFirstDoneTyping(true)
              secondTyping.start()
            }}
          />

          <br />
          {
            // 3 hrs 31 min - Hinge
            // 68 hrs 33 min - Instagram
            // 12 hrs 47 min - iMessage + ~WhatsApp
            // 3:56, :26, 1:07, :02, 1:53, 1:02, 1:40, 4:12, 3:44, 1:51, 1:07,
            // 2:20, :20, 1:13, 1:43, 1:34, 2:23, :03, :02, :38, 2:08, 1:08, :44, :59, :10, :01
            // 3+1+1+1+1+4+3+1+1+2+1+1+1+2+2+1 = 26+15 = 41
            // 56+26+7+2+53+2+40+12+44+51+7+20+20+13+43+34+23+3+2+38+8+8+44+59+10+1+20+20+13+43+34+23+3+2+38+8+44+59+10+1+15 = 959 % 60 = 59
            // 41 hrs 59 min - Instagram - Android
            // 7:49, 8:44, 5:31, 3:45, 0:45 - 26 hrs 34 min
            // 26 hrs 34 min - Instagram - iOS
            // 4:53, 1:45, 4:24
          }
          <Typed
            strings={[
              'Spent 3 hours 31 minutes on Hinge^1500',
              'Spent 68 hours 33 minutes on Instagram^1500',
              'Spent 12 hours 47 minutes on iMessage and WhatsApp^1500',
              'Spent lots of time watching things together 🥺❤️^2000',
              'And granted we already know quite a bit about each other...^1000\n' +
                'I think we should do a fun activity to better know each other 😊',
            ]}
            smartBackspace
            stopped
            typeSpeed={40}
            backSpeed={50}
            backDelay={1000}
            typedRef={(type) => (secondTyping = type)}
            onComplete={() => {
              setTimeout(() => setVolume(volume - 5), 3000 + delayT)
              setDoneTyping(true)
            }}
            showCursor={firstDoneTyping ? true : false}
          />

          <br />
          <br />

          <SlideFade in={doneTyping}>
            <Button
              onClick={() => {
                window.open(
                  'https://www.truity.com/test/enneagram-personality-test'
                )
                setNextRevealed(true)
              }}
            >
              Let's take a quick personality test!
            </Button>
          </SlideFade>
          <SlideFade in={nextRevealed}>
            <IconButton
              variant="outline"
              colorScheme="blue"
              aria-label="Next Screen"
              icon={<ChevronRightIcon />}
              onClick={() => props.history.push('/speed-date-fun-time')}
            />
          </SlideFade>
        </Heading>
      ) : (
        <Spinner size="xl" />
      )}
      <Sound
        url="/summer.mp3"
        playStatus={Sound.status.PLAYING}
        volume={volume}
        onLoad={onLoad}
      />
    </VStack>
  )
}

export default withRouter(Personality)
