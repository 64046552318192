import React, { useState } from 'react'
import {
  Heading,
  Spinner,
  VStack,
  IconButton,
  SlideFade,
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { withRouter } from 'react-router-dom'
import Typed from 'react-typed'
import Sound from 'react-sound'

const History = (props) => {
  let [doneLoading, setDoneLoading] = useState(false)
  let [doneTyping, setDoneTyping] = useState(false)
  let [firstDoneTyping, setFirstDoneTyping] = useState(false)
  let [volume, setVolume] = useState(50)
  let firstTyping = undefined
  let secondTyping = undefined
  let delayT = 100

  const onLoad = () => {
    setDoneLoading(true)
  }

  if (volume < 50 && volume > 0) {
    setTimeout(() => setVolume(volume - 5), delayT)
  }

  return (
    <VStack spacing={8}>
      {doneLoading ? (
        <Heading fontSize="3xl" whiteSpace="pre" lineHeight="tall">
          <Typed
            strings={[
              [
                'Just under a month ago,^500',
                'Our fates matched us on Hinge^1000',
                'And the rest is history!^3000',
                'I have had an amazing time 😊^1000',
              ].join('\n'),
            ]}
            typeSpeed={40}
            backSpeed={50}
            backDelay={2000}
            typedRef={(type) => (firstTyping = type)}
            onComplete={() => {
              setTimeout(() => firstTyping.destroy(), 1000)
              setFirstDoneTyping(true)
              secondTyping.start()
            }}
          />

          <br />

          <Typed
            strings={[
              'From talking about music',
              'To talking about family',
              'To talking about food',
              'To talking about skincare products',
              'To talking about alcohol 🤭',
              'And our dreams',
              'And our wants',
              'And our values',
              'And having late night conversations',
              'And having serious talks',
              'And just having a good time overall 😊',
            ]}
            smartBackspace
            stopped
            typeSpeed={40}
            backSpeed={50}
            backDelay={1000}
            typedRef={(type) => (secondTyping = type)}
            onComplete={() => {
              setTimeout(() => setVolume(volume - 5), 3000 + delayT)
              setDoneTyping(true)
            }}
            showCursor={firstDoneTyping ? true : false}
          />

          <br />
          <br />

          <SlideFade in={doneTyping}>
            <IconButton
              variant="outline"
              colorScheme="blue"
              aria-label="Next Screen"
              icon={<ChevronRightIcon />}
              onClick={() => props.history.push('/personality')}
            />
          </SlideFade>
        </Heading>
      ) : (
        <Spinner size="xl" />
      )}
      <Sound
        url="/this_is_what_you_came_for.mp3"
        playStatus={Sound.status.PLAYING}
        volume={volume}
        onLoad={onLoad}
      />
    </VStack>
  )
}

export default withRouter(History)
